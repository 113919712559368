<template>
  <tr class="flex border-b items-center justify-center">
    <td class="py-4 text-left pl-3 w-[12.5%]"></td>
    <td class="py-4 text-left pl-4 w-[12.5%] flex gap-x-2 ">
      <div>
        <div class="padding_left align_top profile_box cta_link_box">
          <div class="avatar">
            <div v-if="link.information && link.information.favicon_url"
                 class="brand_img"
                 :style="brandImageLink(link.information.favicon_url)"></div>
            <div v-else class="brand_img"
                 style='background: url("/img/globe.svg") center center / cover;'></div>
          </div>
        </div>
      </div>
      <div class="flex justify-start items-center flex-col">
        <div class="name"
             v-if="link.information && link.information.meta && link.information.meta.title">
          {{
            link.information.meta.title
          }}
        </div>
        <div class="font-poppins text-[12px] font-normal"
             v-if="link.status">
          <template>
            <a target="_blank"
               :href="link.domain.url+link.shorten_url" v-tooltip="'Visit link'"
               class="external_link">
              {{ link.domain.url }}{{ link.shorten_url }}
            </a>
          </template>
        </div>
        <div class="url" v-else>
          <template>
          </template>
          <a href="javascript:;"
             v-tooltip="'Visit link'"
             class="external_link">
            {{ link.domain.url }}{{ link.shorten_url }}
          </a>
        </div>
      </div>
    </td>
    <td class="py-4 text-center w-[12.5%]">
      <p class="font-poppins text-[12px]" v-if="link.fetchStats">{{ commaSeparatedNumber(getLinkTotalVisitors) }}</p>
      <skeleton v-else></skeleton>
    </td>
    <td class="py-4 text-center w-[12.5%]">
      <p class="font-poppins text-[12px]" v-if="link.fetchStats">{{ commaSeparatedNumber(getLinkUniqueVisitors) }}</p>
      <skeleton v-else></skeleton>
    </td>
    <td class="py-4 text-center w-[12.5%]">
      <p class="font-poppins text-[12px]" v-if="link.fetchStats">{{ commaSeparatedNumber(getLinkTotalClicksAndConversions) }}</p>
      <skeleton v-else></skeleton>
    </td>
    <td class="py-4 text-center w-[12.5%]">
      <p class="font-poppins text-[12px]" v-if="link.fetchStats">{{ getLinkConversionRate }}%</p>
      <skeleton v-else></skeleton>
    </td>
    <td class="py-4 text-center w-[12.5%]">
      <p class="font-poppins text-[12px]">{{
          link.created_at |
            relativeCaptilize
        }}</p>
    </td>
    <td class="text-right w-[12.5%] pr-3 py-4">
      <a class="flex justify-end items-center" href="javascript:;" @click.prevent="linkDetailView(brand, campaign, link)">
        <svg v-tooltip.top-center="'View Stats'" xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 16 16" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.72226 0.888584C6.72226 0.397664 6.32429 -0.000305176 5.83337 -0.000305176C5.34245 -0.000305176 4.94449 0.397664 4.94449 0.888584V15.1108C4.94449 15.6017 5.34245 15.9997 5.83337 15.9997C6.32429 15.9997 6.72226 15.6017 6.72226 15.1108V0.888584ZM11.0558 6.22257C11.0558 5.73165 10.6578 5.33368 10.1669 5.33368C9.67595 5.33368 9.27798 5.73165 9.27798 6.22257V15.1115C9.27798 15.6024 9.67595 16.0003 10.1669 16.0003C10.6578 16.0003 11.0558 15.6024 11.0558 15.1115V6.22257ZM1.50009 8.8888C1.99101 8.8888 2.38898 9.28677 2.38898 9.77769V15.111C2.38898 15.6019 1.99101 15.9999 1.50009 15.9999C1.00918 15.9999 0.611206 15.6019 0.611206 15.111V9.77769C0.611206 9.28677 1.00918 8.8888 1.50009 8.8888ZM15.3888 2.77726C15.3888 2.28634 14.9908 1.88837 14.4999 1.88837C14.009 1.88837 13.611 2.28634 13.611 2.77726V15.1106C13.611 15.6015 14.009 15.9995 14.4999 15.9995C14.9908 15.9995 15.3888 15.6015 15.3888 15.1106V2.77726Z" fill="#3C4549"/>
        </svg>
      </a>
    </td>
  </tr>
</template>
<script>
import { shortenDomainURL } from '@/config/api'
export default ({
  name: 'analytics-link-section',
  props: {
    link: {},
    brand: {},
    campaign: {}
  },
  data () {
    return {
      shorten_domain_url: shortenDomainURL
    }
  },
  computed: {
    getLinkTotalLinks () {
      return (this.link.total_links) ? this.link.total_links : 0
    },
    getLinkTotalVisitors () {
      return (this.link.total_visitors) ? this.link.total_visitors : 0
      // return (this.link.total_hits) ? this.link.total_hits : 0
    },
    getLinkUniqueVisitors () {
      return (this.link.unique_visitors) ? this.link.unique_visitors : 0
    },
    getLinkTotalClicksAndConversions () {
      return (this.link.total_clicks_and_conversions) ? this.link.total_clicks_and_conversions : 0
    },
    getLinkConversionRate () {
      return (this.link.conversion_rate) ? this.link.conversion_rate : 0
    }
  },
  methods: {}
})
</script>
